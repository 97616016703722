import { isClientSide } from 'src/utils/env/isClientSide';

/* istanbul ignore next */
export const scrollToTop = () => {
  if (!isClientSide()) return;

  if (isClientSide()) {
    window.scrollTo(0, 0);
  }
};

export const blurFromAllElements = () => {
  if (!isClientSide()) return;

  /* istanbul ignore next */
  if (typeof document === 'undefined' || !document.activeElement) {
    return;
  }

  const activeElement = document.activeElement as HTMLElement;

  if (activeElement.blur && document.activeElement !== document.body) {
    activeElement.blur();
  }
};

export const getBrowserLocale = () => {
  if (!isClientSide()) return null;

  return window.navigator.languages && window.navigator.languages.length > 0
    ? window.navigator.languages[0]
    : window.navigator.language;
};
