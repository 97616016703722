import React, { FC, ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Icon, LogoContainer, StreakCounter } from 'src/components';
import { ROUTES } from 'src/constants/routes.const';
import { getAnalyticsLanguageInfo } from 'src/utils/analytics/getAnalyticsLanguageInfo';
import { getAnalyticsUserInfo } from 'src/utils/analytics/getAnalyticsUserInfo';
import { useUserContext } from 'src/context/user';
import * as S from './styles';

const msgs = defineMessages({
  beta: { id: 'communicate_membot_poweredby_GPT3' },
  myActivities: { id: 'myActivities_screenTitle' },
  myWords: { id: 'myWords_title' },
  streak: { id: 'streaks_homepage_header' },
});

interface Props {
  logoUrl?: string;
  lightHeader?: boolean;
  minimalHeader?: boolean;
  dropdown?: ReactNode;
  children?: ReactNode;
  hasBetaTag?: boolean;
  streakCounter?: number;
  streakAccomplishedToday?: boolean;
  onStreakClick?: () => void;
  isAuthorisedUser?: boolean;
}

const Header: FC<Props> = ({
  logoUrl = '/',
  lightHeader,
  minimalHeader,
  dropdown,
  children,
  hasBetaTag,
  streakCounter,
  streakAccomplishedToday,
  onStreakClick,
  isAuthorisedUser,
}) => {
  const intl = useIntl();
  const { user } = useUserContext();

  return (
    <S.HeaderWrapper data-testid="header" $lightHeader={lightHeader}>
      {minimalHeader && <S.ChildrenWrapper $minimalHeader>{children}</S.ChildrenWrapper>}
      <S.HeaderWrapperInner $minimalHeader={minimalHeader}>
        <S.HeaderInner>
          {!minimalHeader && <LogoContainer url={logoUrl} isMobileVisible />}
          {hasBetaTag && (
            <S.BetaTag
              data-testid="Beta-tag"
              href="https://memrise.zendesk.com/hc/en-us/articles/9314957339281"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage(msgs.beta)}
            </S.BetaTag>
          )}
        </S.HeaderInner>
        {!minimalHeader && <S.ChildrenWrapper>{children}</S.ChildrenWrapper>}
        {isAuthorisedUser && (
          <>
            <S.IconWrapper
              href={ROUTES.myActivities}
              data-testid="my_activities_icon"
              aria-label={intl.formatMessage(msgs.myActivities)}
              onClick={() => {
                window.dataLayer.push({
                  event: 'click_cta',
                  action: 'exp_20250326_my_activities',
                  page_screen_type: window.location.pathname,
                  page_screen_name: window.location.pathname,
                  click_area: 'Page Header',
                  click_path: 'Page Header',
                  ...getAnalyticsLanguageInfo(intl),
                  ...getAnalyticsUserInfo(user),
                });
              }}
            >
              <Icon name="statistics" />
            </S.IconWrapper>
            <S.IconWrapper
              href={ROUTES.dictionary}
              data-testid="my_words_icon"
              aria-label={intl.formatMessage(msgs.myWords)}
            >
              <Icon name="book" />
            </S.IconWrapper>
          </>
        )}
        {streakCounter !== undefined && (
          <S.StreakWrapper
            onClick={onStreakClick}
            aria-label={intl.formatMessage(msgs.streak) + ' - ' + streakCounter}
          >
            <StreakCounter accomplishedToday={!!streakAccomplishedToday} counter={streakCounter} />
          </S.StreakWrapper>
        )}
        <S.HeaderInner>{dropdown}</S.HeaderInner>
      </S.HeaderWrapperInner>
    </S.HeaderWrapper>
  );
};

export default Header;
